<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page.sync="perPage"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 500],
        }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        :page.sync="page"
        :server-items-length.sync="total"
        @update:options="getItems"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Connect Experts</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <!-- <template v-slot:activator="{}">
                <v-btn color="primary" dark class="mb-2" v-on:click="addItem"
                  >New Item</v-btn
                >
              </template> -->
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-form
                  ref="form"
                  @submit.prevent="save()"
                  v-model="valid"
                  action="/"
                >
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-text-field
                          :rules="[rules.required]"
                          v-model="editedItem.sort_number"
                          label="Order"
                        ></v-text-field>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text type="submit">Save</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1000px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-switch v-model="item.type === 'member'" v-on:click="setExpertType(item.id, 'member')"/>
        </template>
        <template v-slot:[`item.actions2`]="{ item }">
          <v-switch v-model="item.type === 'steering-committee'" v-on:click="setExpertType(item.id, 'steering-committee')"/>
        </template>
        <template v-slot:[`item.actions3`]="{ item }">
          <v-switch v-model="item.type === 'honorary-member'" v-on:click="setExpertType(item.id, 'honorary-member')"/>
        </template>
        <template v-slot:[`item.actions4`]="{ item }">
          <v-icon
            v-if="item.type === 'member' || item.type === 'steering-committee' || item.type === 'honorary-member'"
            small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getItems">No data</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  fetchExpertsForConnect,
  showExpert,
  // editExpert,
  deleteExpert,
  setExpertType,
  editConnectExpertOrder
} from "@/services";
import TheSidebar from "@/components/TheSidebar";

export default {
  name: "ConnectExperts",
  components: {
    TheSidebar,
  },
  data() {
    return {
      valid: false,
      perPage: 10,
      sortDirection: true,
      page: 1,
      total: 0,
      sortBy: "sort_number",
      spinnerActive: false,
      dialog: false,
      dialogDelete: false,
      expertImage: false,
      headers: [
        { text: "Order", align: "start", value: "sort_number", sortable: false },
        { text: "Prefix", value: "license_prefix", sortable: false },
        { text: "First name", value: "first_name", sortable: false },
        { text: "Last name", value: "last_name", sortable: false },
        { text: "Members", value: "actions", sortable: false },
        { text: "Steering Committee", value: "actions2", sortable: false },
        { text: "Honorary Members", value: "actions3", sortable: false },
        { text: "Order", value: "actions4", sortable: false },
      ],
      items: [],
      website_id: "",
      editedIndex: -1,
      editedItem: {
        first_name: "",
        last_name: "",
        photo: null,
        biography: "",
        type: "",
        license_prefix: "",
        country: "",
      },
      defaultItem: {
        first_name: "",
        last_name: "",
        photo: null,
        biography: "",
        type: "",
        license_prefix: "dr.",
        country: "",
      },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getItems();
    this.websiteId = this.$route.params.websiteId
  },
  methods: {
    getMemberSwitchValue(type) {
      console.log('getMemberSwitchValue => ', type, type === 'member')
      return type === 'member'
    },
    getSteeringCommitteeValue(type) {
      console.log('getSteeringCommitteeValue => ', type, type === 'steering-committee')
      return type === 'steering-committee'
    },
    getHonoraryMemberValue(type) {
      console.log('getHonoraryMemberValue => ', type, type === 'honorary-member')
      return type === 'honorary-member'
    },
    async setExpertType(expert_id, type) {
      let data = {
        expert_id: expert_id,
        website_id: this.websiteId,
        type: type
      }
      await setExpertType(data).then(response => {
        console.log("setExpertType res ==> ", response)
        this.getItems()
      })
    },
    async getItems() {
      this.items = [];
      setTimeout(async () => {
        if (this.spinnerActive) return;
        let tableData = {};
        tableData["perPage"] = this.perPage;
        tableData["sortBy"] = this.sortBy;
        tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC";
        tableData["page"] = this.page;
        tableData["website_id"] = this.websiteId;
        this.spinnerActive = true;
        // console.log('table Pagination ===> ', this.$refs.table)
        await fetchExpertsForConnect(tableData)
          .then((res) => {
            if (res.data) {
              console.log('res', res)
              this.items = res.data.data.data;
              this.spinnerActive = false;
              this.total = res.data.data.total;
            }
          })
          .catch((error) => {
            console.log(error);
            this.spinnerActive = false;
          });
      }, 0);
    },
    loadImage() {
      if (this.editedItem.photo) {
        var file = this.editedItem.photo;
        var reader = new FileReader();
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.expertImage = reader.result;
            this.editedItem.photo = null;
          }
        };
        reader.readAsDataURL(file);
      }
    },
    addItem() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      await showExpert(this.editedItem)
        .then((response) => {
          this.expertImage = response.data.data.photo;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await deleteExpert(this.editedItem)
        .then(() => {
          this.getItems();
          this.closeDelete();
        })
        .then((error) => {
          console.log("deleteExpert error => ", error);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.expertImage = null;
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let formValidated = this.$refs.form.validate();
      if (!formValidated) return;
      let data = {
        sort_number: this.editedItem.sort_number,
        id: this.editedItem.website_expert_id
      }
      await editConnectExpertOrder(data).then(() => {
        this.getItems();
        this.close();
      })
      .then((error) => {
        console.log("addExpert error => ", error);
      });
    },
  },
};
</script>

<style scoped>
/* The switch - the box around the slider */
.button-for-switch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>